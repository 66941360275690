import React from 'react';
import CribIcon from '@mui/icons-material/Crib';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import StarsIcon from '@mui/icons-material/Stars';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

import './experiance.css';

interface ExperienceData {
    title: string;
    subTitle?: string;
    company?: string;
    location?: string;
    year?: string;
    icon?: React.ElementType;
    image?: string;
    duration?: string;
    color?: string;
}

const experianceJSON: ExperienceData[] = [
    {
        title: 'Born',
        year: '1996',
        icon: CribIcon,
        location:"Anatapur, India",
        color:'#F9989F'
    },
    {
        title: 'School',
        year: '2011',
        icon: MenuBookIcon,
        location:"Anatapur, India",
        color:'#FCCB8F'
    },
    {
        title: 'Bachelors Degree',
        subTitle: 'Electronics and Communication Engineering',
        company: 'MALLA REDDY COLLEGE FOR WOMEN',
        location: 'Hyderabad, India',
        year: '2017',
        icon: SchoolIcon,
        color:"#FAF096"
    },

    {
        title: 'Senior Associate',
        subTitle: 'First Job',
        company: 'NTT DATA Services',
        duration: '2017-2019',
        image: './assets/images/ntt.jpg',
        location:'Chennai, India',
        color:"#77E4C8"
    },
    {
        title: 'Cloud Specialist',
        company: 'AIS',
        duration: '2019-2022',
        image: './assets/images/ais.jpg',
        location:'Bengaluru, India',
        color:"#E68369"
    },
    {
        title: 'Post Graduate Degree',
        subTitle: 'Executive PG Diploma Program, Data science',
        company: 'IIIT Bangalore',
        location: 'Bangalore, India',
        year: '2022',
        icon: StarsIcon,
        color:"#C5F8C8"
    },
    {
        title: 'Senior Software Engineer',
        company: 'CBRE',
        duration: '2022',
        image: './assets/images/cbre.jpg',
        color:"#4535C1"
    }
];

const Experience: React.FC<any> = ({ title, company, duration }) => {
    return (
        <div className="timeline" id='timeline'>
            <Typography variant="h4" component="h4"> Journey</Typography>
            <Timeline position="alternate">
                {experianceJSON.map((experience, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            <Typography variant="h6" component="span" sx={{color:"#56daff"}} >
                                {experience.title}
                            </Typography>
                            {experience.subTitle && (
                                <Typography sx={{color:"#1b1b1b"}}>{experience.subTitle}</Typography>
                            )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot sx={{ bgcolor: experience.color, color: "#1b1b1b",height:"32px",width:"32px"}}>
                                {experience.icon && <experience.icon  sx={{ height:"32px",width:"32px"}}/>}
                                {experience.image && <img src={experience.image} alt={experience.company} style={{ height: '32px', width: '32px', borderRadius: '50%' }} />}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            {experience.company && (
                                <Typography>{experience.company}</Typography>
                            )}
                            {experience.location && (
                                <Typography>{experience.location}</Typography>
                            )}
                            {experience.duration && (
                                <Typography>{experience.duration}</Typography>
                            )}
                            {experience.year && (
                                <Typography>{experience.year}</Typography>
                            )}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </div>
    );
};

export default Experience;