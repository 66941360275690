import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import QuiltedImageList from './images';

const Carousel: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
        // Add more image URLs here
    ];

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        // <Box>
        //     <Typography variant="h2">Carousel Component</Typography>
        //     <img src={images[currentIndex]} alt="carousel-image" />
        //     <Button onClick={handlePrevious}>Previous</Button>
        //     <Button onClick={handleNext}>Next</Button>
        // </Box>
        <div className='carousel'>
            <QuiltedImageList />
        </div>
    );
};

export default Carousel;