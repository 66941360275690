import React from 'react';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import { Typography } from '@mui/material';
import './connect.css';



interface ConnectProps {
    // Define your props here
}

const Connect: React.FC<ConnectProps> = () => {
    // Add your component logic here

    const handleEmailClick = () => {
        window.location.href = "mailto:yoshi.palavali@gmail.com";
    };

    return (
        <div className='connet' id='connect'>
            <section className='connect-section'>
                <div className='left' style={{textAlign:"right"}}>
                    <Typography variant="h5" component="h3" className='connet-text' style={{ textAlign: 'right' }}>
                        Have an Idea? <br /> 
                        Struck anywhere? <br />
                        Looking for training?
                    </Typography>
                </div>
                <div className='right' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <Typography variant="h4" component="h3" className='connet' style={{ textAlign: 'center', margin: '0 30px' }}>
                        Let's Connect!
                    </Typography>
                </div>
            </section>
            <div style={{ display: 'flex', justifyContent: 'center' }} className='footer'>
                <IconButton style={{ color: "#9390ca" }} size={'large'} aria-label="email" onClick={handleEmailClick}>
                    <EmailIcon fontSize={'large'} />
                </IconButton>
                <IconButton style={{ color: "#9390ca" }} aria-label="linkedin" onClick={() => window.open('https://www.linkedin.com/in/pyoshithareddy/', '_blank')}>
                    <LinkedInIcon fontSize={'large'} />
                </IconButton>

                <IconButton style={{ color: "#9390ca" }} aria-label="website" onClick={() => window.open('https://bythetechne.com/', '_blank')}>
                    <LanguageIcon fontSize={'large'} />
                </IconButton>
            </div>
        </div>
    );
};

export default Connect;