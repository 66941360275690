import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import './about.css';

const isSmallScreen = window.innerWidth < 800;

const About: React.FC = () => {
    return (
        <div className='about-me' id='about-me'>
            <Grid container spacing={2} style={{ height: "100%" }}>
                <Grid item xs={12} md={6} lg={6} style={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                    <Box >
                        <Typography variant="h1" fontSize="5em" color="#fff" fontFamily="Roobert,sans-serif" component="h1">
                            Yoshitha Palavali
                        </Typography>
                        <Typography variant="h6" fontSize="13px" textAlign="left" marginBottom={isSmallScreen? 0 :"40px" } color="#fff" fontFamily="Roobert,sans-serif" component="h6">
                            Power BI Developer | Microsoft Certified Trainer
                        </Typography>

                        {isSmallScreen && <div  style={{marginTop:0}} className='about-me-image'>
                        <div className='image'>
                        </div>
                    </div>
                    }

                    </Box>
                    <Typography variant="body1" component="p" >
                        With over 7 years of experience, I specialize in data analysis using <span className="highlight">Power BI</span>, <span className="highlight">Power Automate</span>, and <span className="highlight">.NET</span> technologies. As a <span className="highlight">MCT</span> and <span className="highlight">Power BI trainer</span>, I’ve had the privilege of  <span className="highlight">training over 500+</span> Data Enthusiasts, including <span className="highlight">India Navy personnel</span>, <span className="highlight">college students </span>, and <span className="highlight">corporate teams</span>.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ height: "100%" }}>
                    {!isSmallScreen && <div className='about-me-image'>
                        <div className='image'>
                        </div>
                    </div>}
                </Grid>
            </Grid>


        </div>
    );
};

export default About;