import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { text } from 'stream/consumers';
import { useMediaQuery, Theme } from '@mui/material';

import './images.css';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}



export default function QuiltedImageList() {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const isSmallscreen = window.screen.availWidth < 800;
  return (
    <div>
      <Typography variant="h4" component="h4" style={{ textAlign: 'left', margin: '20px 0' }}>
        Trainings
      </Typography>
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        variant="quilted"
        cols={isSmallscreen? 1 : 4}
        rowHeight={isSmallscreen? 200 :121}
      >
        {itemData.map((item, index) => (
          <ImageListItem 
            key={item.img} 
            cols={isSmallscreen ? 1 : item.cols || 1} 
            rows={isSmallscreen ? 1 : item.rows || 1}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
            style={{ position: 'relative' }}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              loading="lazy"
              className='training-image'
            />
            {hoveredItem === index && item.text && (
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                {item.text}
              </div>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: '/assets/images/personal/1.jpg',
    rows: 2,
    cols: 2,
    text:'Hyderabad Data Community'
  },
  {
    img: '/assets/images/personal/2.jpg',
    rows: 2,
    cols: 1,
    text:'India Navy'
  },
  {
    img: '/assets/images/personal/6.jpg',
    rows: 2,
    cols: 1,
    text:"Guntur College"
  },
  {
    img: '/assets/images/personal/3.jpg',
    rows: 3,
    cols: 3,
    text:'India Navy'
  },
  {
    img: '/assets/images/personal/4.jpg',
    text:'Bengaluru College'
  },
  {
    img: '/assets/images/personal/5.jpg',
    text: 'Corporate Training'

  },
  {
    img: '/assets/images/personal/7.jpg',
    text: 'Hyderabad College'
  },

];
