import React from 'react';
import { Grid, Typography } from '@mui/material';
import './certifications.css';


const Certifications: React.FC = () => {
    return (
        <section className='certifications' id="certifications">
            <Typography style={{ margin: "20px 0" }} variant="h4" component="h4">
                Certifications
            </Typography>
            <Grid container style={{ textAlign: 'center' }} spacing={2}>
                <Grid item xs={12} md={4}>
                    <img src="/assets/images/data analyst.png" alt="Data Analyst" className='cert-image' />
                    <Typography variant="body1" className='certificateTitle' fontSize={13} component="div">
                        Microsoft Certified: Data Analyst Associate
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/assets/images/Azure.png" alt="Azure Fundamentals" className='cert-image' />
                    <Typography variant="body1" className='certificateTitle' fontSize={13} component="div">
                        Microsoft Certified: Azure Fundamentals
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src="/assets/images/mct.jpg" alt="MCT certified Power BI Instructor" className='cert-image mct' />
                    <Typography variant="body1" className='certificateTitle' fontSize={13} component="div">
                        Microsoft Certified: MCT certified Power BI Instructor
                    </Typography>
                </Grid>
            </Grid>
        </section>
    );
}

export default Certifications;