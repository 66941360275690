import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import About from '../about/about';
import Experience from '../experiance/experiance';
import Connect from '../connect/connect';
import Carousel from '../carousel/carousel';
import Skills from '../skills/skills';
import Summary from '../summary/summary';
import Certifications from '../certifications/certifications';

import './home.css';

const isSmallScreen = window.innerWidth < 800;


const buttonStyle = {
  background: "rgba(255,255,255,.1)",
  margin: "0 10px",
  borderRadius: "100px",
  textTransform: "capitalize"
};

const buttons = [
  { id: 'about-me', label: 'Home' },
  { id: 'skills', label: 'Skills' },
  { id: 'timeline', label: 'Experience' },
  { id: 'certifications', label: 'Certifications' },
  { id: 'connect', label: 'Contact' },
];


function Home() {
  const handleScroll = (id: string) => {
    const aboutMeSection = document.getElementById(id);
    if (aboutMeSection) {
      aboutMeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className='header'>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ color: "#eee", background: "none", boxShadow: "none" }}>
            <Toolbar style={{ display: 'flex', justifyContent: "center" }} >
              {buttons.map((button) => (
                <Button
                  key={button.id}
                  className="hoverEffect"
                  style={{
                    background: isSmallScreen? "none": "rgba(255,255,255,.1)",
                    margin: isSmallScreen? "0 3px": "0 10px",
                    textTransform: "capitalize",
                    minWidth: "auto"
                  }}
                  color="inherit"
                  onClick={() => handleScroll(button.id)}
                >
                  {button.label}
                </Button>
              ))}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <About />
      <Skills />
      <Experience />
      <Certifications />
      <Carousel />
      <Summary />
      <Connect />
    </div>
  );
}

export default Home;