// src/App.js

import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Home from './components/home/home';

function App() {
  return (
    <Container>
      <Home />
    </Container>
  );
}

export default App;
