import React from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import HikingIcon from '@mui/icons-material/Hiking';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import PetsIcon from '@mui/icons-material/Pets';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import FlightIcon from '@mui/icons-material/Flight';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const Summary: React.FC = () => {
    return (
        <section className="highlights" id='highlights' style={{ margin: "20px 0" }}>
            <Box className="more-about-me">
                <Typography variant="h4" component="h4">
                    More about me
                </Typography>
                <Grid container spacing={0} item xs={12} lg={12} style={{ textAlign: "center",color:"#cbc9f3" }}>
                    <Grid item xs>
                        <Tooltip title="Hiking">
                            <HikingIcon fontSize="large" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="Yoga">
                            <SelfImprovementIcon fontSize="large" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="2 Dogs">
                            <PetsIcon fontSize="medium" />
                        </Tooltip>
                        <Tooltip title="2 Dogs">

                            <PetsIcon fontSize="large" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="Travel Around the world">
                            <FlightIcon fontSize='large'></FlightIcon>
                        </Tooltip>
                        <Tooltip title="Travel Around the world">
                            <TravelExploreIcon fontSize='large'></TravelExploreIcon>
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="Music and Dance">
                            <MusicNoteIcon fontSize="large" />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            <Box className='summary' component="div" style={{ textAlign: 'center',color:"#cbc9f3" }}>
                <Typography variant="body1" fontSize={13} component="span">
                    Power BI Developer
                </Typography>
                <Typography style={{ margin: "0 10px" }} variant="body1" component="span">
                    |
                </Typography>
                <Typography variant="body1" fontSize={13} component="span">
                    Microsoft Certified Trainer
                </Typography>
                <Typography style={{ margin: "0 10px" }} variant="body1" component="span">
                    |
                </Typography>
                <Typography variant="body1" fontSize={13} component="span">
                    Senior Software Engineer at CBRE
                </Typography>
                <Typography style={{ margin: "0 10px" }} variant="body1" component="span">
                    |
                </Typography>
                <Typography variant="body1" fontSize={13} component="span">
                    Founder of Techne
                </Typography>
                <Typography style={{ margin: "0 10px" }} variant="body1" component="span">
                    |
                </Typography>
                <Typography variant="body1" fontSize={13} component="span">
                    Trained Over 500 Data Enthusiasts
                </Typography>
            </Box>

        </section>
    );
};

export default Summary;