import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1
    },
    skillItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    skillImage: {
        width: 'auto',
        height: 100
    },
}));

const Skills: React.FC = () => {
    const classes = useStyles();

    return (
        <div className="skills" id='skills'>
            <Typography variant="h4" component="h4" style={{ textAlign: 'left' }}>
                Skills</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <div className={classes.skillItem}>
                        <img src="/assets/images/powerbi.png" alt="Power BI" className={classes.skillImage} />
                        <Typography variant="h6">Power BI</Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <div className={classes.skillItem}>
                        <img src="/assets/images/sql.png" alt="SQL" className={classes.skillImage} />
                        <Typography variant="h6">SQL</Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <div className={classes.skillItem}>
                        <img src="/assets/images/powerapp.png" alt="Power Apps" className={classes.skillImage} />
                        <Typography variant="h6">Power Apps</Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <div className={classes.skillItem}>
                        <img src="/assets/images/azure-logo.png" alt="Azure" className={classes.skillImage} />
                        <Typography variant="h6">Azure</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Skills;